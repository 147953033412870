<template>
  <div>
    <div class="pt-2 container d-flex justify-content-center">
      <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" v-bind:svg-inline="''" v-bind:class="'align-self-center icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M68.748 86.606c-3.131-1.77-7.094-5.949-9.45-9.965-4.489-7.653-6.938-17.13-7.754-30.01-.353-5.56-.593-6.696-1.48-7.005-3.19-1.11-8.578 2.932-14.877 11.159C25.147 63.897 23.582 65.28 18.786 65.28c-6.317 0-8.742-6.49-5.042-13.494 1.963-3.717 7.041-8.03 13.539-11.5 7.03-3.753 11.746-5.042 18.75-5.123l5.72-.067.982-3.898c2.326-9.22 7.887-15.194 16.861-18.11C77.793 10.424 88 12.928 88 17.604c0 3.31-4.991 6.015-17.323 9.386-13.241 3.62-16.442 5.215-16.442 8.195 0 .428 1.875 2.145 4.167 3.815C75.77 51.655 86.266 77.019 77.92 86.167c-2.076 2.275-5.624 2.445-9.172.44z"/></svg>
      <h3 class="align-self-center">telenor</h3>
    </div>
    <div class="content d-flex justify-content-center">
      <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'align-self-center icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><circle fill="#46E19A" cx="12" cy="12" r="12"/><path d="M18.01 8.476a1 1 0 010 1.414l-6.364 6.364a2 2 0 01-2.828 0L5.99 13.426a1 1 0 011.414-1.414l2.121 2.121a1 1 0 001.414 0l5.657-5.657a1 1 0 011.414 0z" fill="#FFF"/></g></svg>
    </div>
    <p class="pt-3 d-flex justify-content-center"><b>Identifisering gjennomført</b></p>
    <p class="pt-3 d-flex justify-content-center text-center">Hei, {{ customerName }}</p>
  </div>
</template>

<script>
export default {
  name: "Success",

  computed: {
    customerName(){
      return `${this.$route.query.firstName} ${this.$route.query.lastName}`
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) '../assets/styles/main';

.container {
  flex-grow: 1;
}
.icon {
  fill: @color-telenor-blue;
  height: 50px;
  width: 50px;
}
.content {
  padding-top: 200px;
}

p {
  font-size: 24px;
}
</style>